// import React from 'react'
// import styles from './styles.module.css'
// import Container from  '@packages/justo-parts/lib/components/Container'
// import Code from './Code'
// import useUserId from '@hooks/useUserId'
// import Login from './Login'

export default function Referrals(props) {
  return null
  // const userId = useUserId()
  // return (
  //   <div
  //     style={{
  //       backgroundColor: props.color,
  //       color: '#fff'
  //     }}>
  //     <Container size="small">
  //       <div className={styles.container}>
  //         <div className={styles.inner}>
  //           <div className={styles.message}>{props.message}</div>
  //           {userId ? <Code /> : <Login />}
  //         </div>
  //         <div className={styles.description}>{props.conditions}</div>
  //       </div>
  //     </Container>
  //   </div>
  // )
}
